import createSSO from '@ngin-io/react-sso/createSSO.js';
import * as Sentry from '@sentry/nextjs';
import Router from 'next/router';
import SpinnerFull from '../SpinnerFull';
import ErrorPage from '../pages/ErrorPage';

export const {
  AuthProvider,
  useAuthentication,
  RedirectComponent,
  fetchV3Authenticated,
  fetchV3,
} = createSSO({
  // enviroment variables:
  appURL:
    typeof window === 'undefined'
      ? 'notNeededOnServer'
      : window.location.origin, // if run on server due to next dev env, pass a string which prevents an error from being thrown
  apiAuthURL: process.env.NEXT_PUBLIC_SSO_URL,
  apiV3URL: process.env.NEXT_PUBLIC_API_URL,
  redirectPath: '/login/oauth/redirect',
  clientId: process.env.NEXT_PUBLIC_SSO_CLIENT_ID,

  // optional components:
  loadingComponent: SpinnerFull,
  errorComponent: ErrorPage,

  // callbacks:
  onLogin(path) {
    Router.replace(path);
  },
  onError(error) {
    Sentry.captureException(error);
  },
});
