import Notification from '@ngin-io/components/public/components/Notification/Notification.js';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { useMemo, useReducer, useRef } from 'react';
import NotificationsContext from './NotificationsContext';

const initialState = {
  active: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'notify': {
      const { variant, title, description, dismissable, actions } = action;
      return {
        active: true,
        variant,
        title,
        description,
        dismissable,
        actions,
      };
    }
    case 'dismiss':
      return initialState;
    default:
      throw new Error('Unknown action.');
  }
};

const NotificationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const timeoutRef = useRef();

  const context = useMemo(() => {
    const notify = ({
      variant,
      title,
      description,
      timeout = 8000,
      fixed = false,
      dismissable = true,
      actions,
    }) => {
      dispatch({
        type: 'notify',
        variant,
        title,
        description,
        dismissable: fixed ? false : dismissable,
        actions,
      });
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (fixed) {
        return;
      }
      if (timeout !== false) {
        timeoutRef.current = setTimeout(() => {
          dispatch({ type: 'dismiss' });
        }, timeout);
      }
    };

    return {
      notify,
    };
  }, []);

  return (
    <NotificationsContext.Provider value={context}>
      {children}
      <AnimatePresence>
        {state.active && (
          <motion.div
            positionTransition
            initial={{ opacity: 0, y: 50, scale: 0.3 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
            css={{
              padding: '0 3',
              display: 'flex',
              justifyContent: 'center',
              position: 'fixed',
              top: 3,
              width: '100%',
              height: 0,
              zIndex: 9,
            }}
          >
            <div>
              <Notification
                variant={state.variant}
                title={state.title}
                description={state.description}
                actions={state.actions}
                onDismiss={
                  state.dismissable
                    ? () => {
                        dispatch({ type: 'dismiss' });
                        clearTimeout(timeoutRef.current);
                      }
                    : undefined
                }
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </NotificationsContext.Provider>
  );
};

NotificationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NotificationsProvider;
