// ignore unused exports default
import mixpanel from 'mixpanel-browser';
import { DefaultSeo } from 'next-seo';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import AppContainer from '../components/AppContainer';
import GlobalHeadTags from '../components/GlobalHeadTags';
import { defaultMetaTags } from '../config/defaultMetaTags';

mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, {
  debug: process.env.NODE_ENV !== 'production',
});

const App = ({ Component, pageProps, err }) => {
  return (
    <React.Fragment>
      <GlobalHeadTags />
      <AppContainer>
        <DefaultSeo {...defaultMetaTags} />
        <Component {...pageProps} err={err} />
        <ReactQueryDevtools />
      </AppContainer>
    </React.Fragment>
  );
};

App.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object,
  err: PropTypes.object,
};

export default App;
