import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import Logo from './Logo';

const ErrorLayout = ({ children }) => {
  const router = useRouter();

  return (
    <main
      role="main"
      id="main-content"
      css={{
        backgroundImage: 'url("/404-bg.svg")',
        backgroundSize: 'cover',
        backgroundPositionX: 'left',
        backgroundColor: 'forestgreen.700',
        height: '-webkit-fill-available',
      }}
    >
      <div
        css={{
          height: '100vh',
          display: 'flex',
          flexGrow: 1,
          alignItems: 'center',
          paddingRight: [3, 6],
          paddingLeft: [3, 6],
        }}
      >
        <div
          css={{
            maxWidth: 'content.condensed',
            fontWeight: 'bold',
            color: 'midnightivory.100',
          }}
        >
          <Logo
            modifiers={{ fill: 'white' }}
            onClick={() => {
              return router.push('/');
            }}
            css={{ width: '204px', marginBottom: 5 }}
          />
          {children}
        </div>
      </div>
    </main>
  );
};

ErrorLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorLayout;
