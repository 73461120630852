import React from 'react';

/**
 * ? Reference: https://github.com/vercel/next.js/tree/canary/examples/with-google-tag-manager
 */

const GTM_AUTH_CODE = process.env.NEXT_PUBLIC_GTM_AUTH_CODE;
const GTM_TRACKING_ID = process.env.NEXT_PUBLIC_GTM_TRACKING_ID;
const isProduction = process.env.NODE_ENV.toLowerCase() === 'production';

// ? https://github.com/vercel/next.js/blob/canary/examples/with-google-tag-manager/lib/gtm.js
const trackPageViewGTM = (url) => {
  if (isProduction) {
    window.dataLayer.push({
      event: 'pageview',
      page: url,
    });
  }
};

const GTMTrackingHead = () => {
  if (isProduction) {
    return (
      <script
        dangerouslySetInnerHTML={{
          __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl+'&gtm_auth=${GTM_AUTH_CODE}&gtm_preview=env-3&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${GTM_TRACKING_ID}')
            `,
        }}
      />
    );
  }

  return null;
};

const GTMTrackingBody = () => {
  if (isProduction) {
    return (
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${GTM_TRACKING_ID}&gtm_auth=${GTM_AUTH_CODE}&gtm_preview=env-3&gtm_cookies_win=x`}
          height="0"
          width="0"
          style={{
            display: 'none',
            visibility: 'hidden',
          }}
        />
      </noscript>
    );
  }

  return null;
};

export { GTMTrackingHead, GTMTrackingBody, trackPageViewGTM };
