import NextLink from 'next/link';
import PropTypes from 'prop-types';
import LinkInner from './LinkInner';

const Link = ({ href, children, ...props }) => {
  return (
    <NextLink href={href} passHref>
      <LinkInner {...props}>{children}</LinkInner>
    </NextLink>
  );
};

Link.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Link;
