import GlobalStyles from '@ngin-io/components/public/lib/GlobalStyles.js';
import MysticalProvider from 'mystical/public/MysticalProvider.js';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

const options = {
  disableCascade: false,
};

const ThemeProvider = memo(({ children, ...props }) => {
  return (
    <MysticalProvider options={options} {...props}>
      <GlobalStyles />
      {children}
    </MysticalProvider>
  );
});

ThemeProvider.displayName = 'ThemeProvider';

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeProvider;
