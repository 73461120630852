import theme from '@ngin-io/components/public/themes/btcm/theme.js';
import mixpanel from 'mixpanel-browser';
import Router, { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect, useLayoutEffect as useReactLayoutEffect } from 'react';
import { QueryClientProvider } from 'react-query';
import ThemeProvider from '../config/ThemeProvider';
import isServer from '../lib/isServer';
import ErrorBoundary from './ErrorBoundary';
import FBPixel from './FBPixel';
import { trackPageView } from './GATracking';
import { trackPageViewGTM } from './GTMTracking';
import GlobalStyles from './GlobalStyles';
import GlobalContainerContext from './lib/GlobalContainerContext';
import NotificationsProvider from './lib/notifications/NotificationsProvider';
import queryClient from './lib/queryClient';
import { AuthProvider, useAuthentication } from './lib/sso';
import ErrorPage from './pages/ErrorPage';

const useLayoutEffect = isServer ? useEffect : useReactLayoutEffect;

// Reference: https://www.techhive.io/our-insights/how-to-build-a-powerful-blog-with-nextjs-and-contentful
Router.events.on('routeChangeComplete', (url) => {
  return process.env.NEXT_PUBLIC_USE_GOOGLE_TAG_MANAGER === 'true'
    ? trackPageViewGTM(url)
    : trackPageView(url);
});

const context = {};

const MixpanelIdentify = () => {
  const { userId, loggedIn } = useAuthentication();

  useEffect(() => {
    if (loggedIn && userId) {
      mixpanel.identify(userId);
      mixpanel.people.set_once({ userId, 'First login': new Date() });
    } else {
      mixpanel.reset();
    }
  }, [loggedIn, userId]);

  return null;
};

// Links coming from authenticated parts of the legacy app can pass
// `auth=true` as a query parameter in order to automatically login.
const AutoLoginFromReferrer = () => {
  const { login, loggedIn } = useAuthentication();

  const router = useRouter();

  useLayoutEffect(() => {
    if (router.query.auth === 'true' && !loggedIn) {
      login();
    }
  }, [loggedIn, router.query.auth, login]);

  return null;
};

const AppContainer = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <FBPixel />
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ErrorBoundary fallback={<ErrorPage />}>
            <NotificationsProvider>
              <GlobalContainerContext.Provider value={context}>
                <GlobalStyles />
                <MixpanelIdentify />
                <AutoLoginFromReferrer />
                {children}
              </GlobalContainerContext.Provider>
            </NotificationsProvider>
          </ErrorBoundary>
        </AuthProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

AppContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppContainer;
