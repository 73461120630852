// ? Reference: https://www.npmjs.com/package/next-seo
const defaultName = 'BTC Markets';
const defaultTitle = 'BTC Markets Bitcoin Exchange | Buy & Sell BTC With AUD';
// eslint-disable-next-line prettier/prettier
const defaultDescription = 'Australia\'s premier secure cryptocurrency & Bitcoin exchange. Buy, sell and trade Bitcoin (BTC), Ethereum (ETH), XRP and more with AUD today.';

// TODO - Move icons to CDN?
const defaultMetaTags = {
  title: defaultTitle,
  description: defaultDescription,
  canonical: 'https://v2.btcmarkets.net/',
  openGraph: {
    type: 'website',
    locale: 'en_AU',
    url: 'https://v2.btcmarkets.net/',
    description: defaultDescription,
    images: [
      {
        url: 'https://assets.btcmarkets.net/images/og-image-1200x1200.jpg',
        width: 1200,
        height: 1200,
        alt: defaultTitle,
      },
      {
        url: 'https://assets.btcmarkets.net/images/og-image-398x398.jpg',
        width: 398,
        height: 398,
        alt: defaultTitle,
      },
    ],
    site_name: defaultName,
  },
  twitter: {
    cardType: 'summary_large_image',
    site: '@BTCMarkets',
    handle: '@BTCMarkets',
  },
  additionalMetaTags: [
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1',
    },
    {
      name: 'application-name',
      content: defaultName,
    },
    {
      name: 'apple-mobile-web-app-capable',
      content: 'yes',
    },
    {
      name: 'apple-mobile-web-app-status-bar-style',
      content: 'default',
    },
    {
      name: 'apple-mobile-web-app-title',
      content: defaultName,
    },
    {
      name: 'format-detection',
      content: 'telephone=no',
    },
    {
      name: 'mobile-web-app-capable',
      content: 'yes',
    },
    {
      name: 'msapplication-config',
      content: '/browserconfig.xml',
    },
    {
      name: 'msapplication-tap-highlight',
      content: 'no',
    },
    {
      name: 'msapplication-TileColor',
      content: '#032e29',
    },
    {
      name: 'theme-color',
      content: '#032e29',
    },
  ],
};

export { defaultMetaTags };
