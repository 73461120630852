import IconLogo from '@ngin-io/components/public/components/Icons/IconLogo.js';
import PropTypes from 'prop-types';
import Link from './Link';

const Logo = ({ modifiers = { fill: 'forestgreen.900' }, ...props }) => {
  return (
    <Link
      {...props}
      href="https://www.btcmarkets.net/"
      title="BTC Markets Bitcoin Exchange | Buy & Sell BTC With AUD"
      css={{
        display: 'inline-block',
      }}
    >
      <IconLogo modifiers={modifiers} />
    </Link>
  );
};

Logo.propTypes = {
  modifiers: PropTypes.object,
};

export default Logo;
