import Spinner from '@ngin-io/components/public/components/Spinner/Spinner.js';

const SpinnerFull = () => {
  return (
    <div css={{ display: 'flex', height: '100vh' }}>
      <Spinner css={{ flex: 1, alignSelf: 'center' }} />
    </div>
  );
};

export default SpinnerFull;
