// ignore unused exports
import React from 'react';

/**
 * ? Reference: https://www.techhive.io/our-insights/how-to-build-a-powerful-blog-with-nextjs-and-contentful
 * ? Reference: https://developers.google.com/analytics/devguides/collection/gtagjs/pages
 */

// Add your GA tracking id here
const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_CODE;
const isProduction = process.env.NODE_ENV.toLowerCase() === 'production';

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
const trackPageView = (url) => {
  if (isProduction) {
    window.gtag('config', GA_TRACKING_ID, {
      page_path: url,
    });
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
const trackEvent = ({ action, category, label, value }) => {
  if (isProduction) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
    });
  }
};

const GATracking = () => {
  if (isProduction) {
    return (
      <React.Fragment>
        <link rel="preconnect" href="https://www.google-analytics.com" />
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
        />
        {/* We call the function above to inject the contents of the script tag */}
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${GA_TRACKING_ID}', { 'page_path': window.location.pathname });
            `,
          }}
        />
      </React.Fragment>
    );
  }

  return null;
};

export default GATracking;
export { GA_TRACKING_ID, isProduction, trackEvent, trackPageView };
