import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/nextjs';

if (process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true') {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    integrations: [
      new CaptureConsoleIntegration({
        // array of methods that should be captured
        // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels: ['error'],
      }),
    ],
    // https://github.com/getsentry/sentry-javascript/issues/1964#issuecomment-625353135
    // Undocumented, allows for nested objects to be passed to Sentry.
    // Defaults to 3.
    normalizeDepth: 5,
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps

    // Ignore errors from Google Recaptcha rejecting with a primitive instead of an Error object.
    // https://github.com/getsentry/sentry-javascript/issues/2514#issuecomment-603971338
    // UnhandledRejection
    // Non-Error promise rejection captured with value: Timeout
    beforeSend(event, hint) {
      if (hint.originalException === 'Timeout') {
        return null;
      }
      return event;
    },
  });
}
