import Button from '@ngin-io/components/public/components/Button/Button.js';
import Heading from '@ngin-io/components/public/components/Heading/Heading.js';
import TextList from '@ngin-io/components/public/components/TextList/TextList.js';
import PropTypes from 'prop-types';
import ErrorLayout from '../ErrorLayout';

const ErrorPage = ({ message }) => {
  return (
    <ErrorLayout>
      <Heading
        as="h1"
        css={{
          marginBottom: 3,
          fontSize: [7, , 8],
          fontWeight: 'normal',
        }}
      >
        Something went wrong!
      </Heading>
      <div>
        {message && <p css={{ fontFamily: 'monospace' }}>{message}</p>}

        <p>Please try:</p>

        <TextList>
          <li>Checking your internet connection</li>
          <li>Reloading the page</li>
        </TextList>

        <p css={{ marginTop: 4 }}>
          If you continue to see this page frequently, please{' '}
          <a
            css={{
              color: 'midnightivory.400',
              ':hover': { color: 'midnightivory.600' },
            }}
            href="https://support.btcmarkets.net/hc/en-us"
            target="_blank"
            rel="noreferrer"
          >
            contact us.
          </a>
        </p>
      </div>
      <div css={{ marginTop: 4 }}>
        <Button
          css={{ display: 'inline-block' }}
          as="a"
          onClick={() => {
            location.reload();
          }}
          color="ivory"
          variant="secondary"
        >
          Reload
        </Button>
        <Button
          css={{ display: 'inline-block', marginLeft: 3 }}
          as="a"
          onClick={() => {
            window.location = '/';
          }}
          color="ivory"
          variant="tertiary"
        >
          Go to homepage
        </Button>
      </div>
    </ErrorLayout>
  );
};

ErrorPage.propTypes = {
  message: PropTypes.string,
};

export default ErrorPage;
